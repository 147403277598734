<template>
	<div class="popup active">
        <div class="notice_popup prj_itv state asgMng">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
            </div>
            <div class="tit">
                <span v-if="param.classDivCd == '03' || param.classDivCd == '05'">참여소속사 확인</span>
                <span v-else>원소속사 확인</span>
            </div>

            <div class="board">
                <table class="Board_type2">
                    <colgroup>
                        <col width="210">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr style="line-height: 60px;">
                            <th>
                                <span v-if="param.classDivCd == '03' || param.classDivCd == '05'">참여소속사</span>
                                <span v-else>회원소속사</span>
                            </th>
                            <td v-if="param.corpNm == '' || param.corpNm == null ">소속사 없음</td>
							<td v-else>{{this.param.corpNm}}</td>
                        </tr>
                        <tr v-if="param.classDivCd != '03' && param.classDivCd != '05'">
                            <th>직 원 구 분</th>
                            <td><SelectComp type="text" cdId="PRO102" v-model="param.empDivCd" defValue="01" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="subCont">
                <span v-if="param.classDivCd == '04'">소속사 탈퇴 요청 하시겠습니까?</span>
                <span v-else-if="(param.classDivCd == '03' && param.mberJoinCorpDiv == '3') || param.classDivCd == '05'">참여소속사 수정 하시겠습니까?</span>
                <span v-else>선택하신 위의 정보로 승인 요청 하시겠습니까?</span>
            </div>

            <div class="select-btn">
                <div class="btn" @click="$emit('close')">취소</div>
                <div class="btn" @click="asgApprReq()">예</div>
            </div>

            <div class="notice_box">
                <span v-if="param.classDivCd == '03' || param.classDivCd == '05'"> 
                    <p class="tit">&#8251; 프로젝트 참여소속사 승인 관련 유의사항</p>
                    <p>1. 프로젝트 참여 소속사가 본인의 인력공급 계약주체로서 계약을 수요기업과 체결하게 됩니다</p>
                    <p>2. 프로젝트 참여소속사를 변경하실 경우, 변경하실 참여소속사를 선택하셔서 승인 받으시면 됩니다.</p>
                    <p>&#9733; ‘참여소속사 없음’으로 등록하실 경우 별도의 승인절차는 없습니다.</p>     
                </span>
                <span v-else>
                    <p class="tit">&#8251; 원소속사 승인 관련 유의사항</p>
                    <p>1. 원소속사가 있는 경우, 원소속사 승인을 받아야 합니다. (원소속사가 있는 경우, 승인을 받지 않으면 프로젝트에 지원할 수 없음)</p>
                    <p>2. 원소속사 승인은 해당 원소속사의 상황에 따라 처리기간이 달라질 수 있음을 알려드립니다.</p>
                    <p>3. '원소속사 없음'을 선택하실 경우, 직원구분은 '프리랜서'로 자동설정 됩니다.</p>
                    <p>&#9733; 원소속사 변경을 하실 경우 '소속탈퇴'를 하신 후 변경하실 소속사 승인을 받으셔야 합니다.</p>
                </span>
			</div>
            
        </div>
    </div>
</template>

<script>
export default {
	props: {
		param : Object,
	},
	
	data() {
		return {
			data : this.param,
           
		}
	},

	methods : {
        asgApprReq() {
            var param = {
                mberInfo : this.data,
            }

            param.mberInfo.aftAsgApprYn = '';

            if((this.data.classDivCd == '02' && this.data.corpSeq == '' && this.data.empDivCd == '02') || (this.data.classDivCd == '03' && this.data.mberJoinCorpDiv == '3') || this.data.classDivCd == '05'){
                param.mberInfo.aftAsgApprYn = 'Y';
            }
            
            if(this.data.classDivCd == '04'){
                param.mberInfo.empDivCd = '02';
            }

            this.$.httpPost('/api/mem/prf/insertAsgApprReq', param)
                .then(() => {
                    if(this.data.classDivCd == '05') alert('수정되었습니다.');
                    else alert('승인요청되었습니다.');

                    this.$emit('close');
                    this.$router.go(0);
                    
                }).catch(err => {
                    alert(err.response.data.error_description);
            }); 
			
        },
	}
}
</script>
